import AiFillDelete from "@/public/icons/AiFillDelete.svg"
import IoIosClose from "@/public/icons/IoIosClose.svg"

import { FC, useEffect, useRef, useState } from "react"

import useInView from "@/lib/hooks/useInView"
import { useMembershipBooking } from "@/lib/hooks/useMembershipBooking"
import { Cart as ICart } from "@/lib/typings/Cart"
import { cn } from "@/lib/utils"

import { useAuth } from "@/Context/auth.context"
import { useCart } from "@/Context/cart.context"

import NonRecurringDialog from "./NonRecurrindDialog"
import SelectStartDateDialog from "./SelectStartDateDialog"
import { Dialog, DialogTrigger } from "./ui/dialog"

const Cart: FC = () => {
  const { user } = useAuth()
  const [isVisible, divRef] = useInView()
  const { cartOpen, cart, closeCart, setFetchData } = useCart()
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const cartRef = useRef<HTMLDivElement>(null)
  const { membershipItems } = useMembershipBooking()
  // const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    if (isVisible) {
      setFetchData(true)
    }
  }, [isVisible])

  useEffect(() => {
    if (!cartRef.current) {
      return
    }
    const clickAwayListener = (e: MouseEvent) => {
      if (cartRef.current?.contains(e.target as Node)) {
        return
      } else {
        closeCart()
      }
    }

    const escapeListener = (ev: globalThis.KeyboardEvent) => {
      if (ev.key === "Escape") {
        closeCart()
      }
    }
    window.addEventListener("mousedown", clickAwayListener)
    window.addEventListener("keydown", escapeListener)

    return () => {
      window.removeEventListener("mousedown", clickAwayListener)
      window.removeEventListener("keydown", escapeListener)
    }
  }, [cartRef, closeCart])

  useEffect(() => {
    const body = window.document.body

    if (cartOpen) {
      body.classList.add("lock")
    } else {
      body.classList.remove("lock")
    }
  }, [cartOpen])

  // useEffect(() => {
  //   const cartSimplified = cart?.lines.edges
  //     .filter((item) => {
  //       const title = item.node.merchandise.product.title.toLowerCase()
  //       return (
  //         title.includes("gold") ||
  //         title.includes("silver") ||
  //         title.includes("bronze")
  //       )
  //     })
  //     .map((res) => res.node.merchandise.id)

  //   setDisabled((cartSimplified || []).length > 0)
  // }, [cart])

  const amount = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(Number(cart?.cost?.subtotalAmount?.amount || 0))

  return (
    <Dialog>
      <div
        className={cn(
          "h-full z-[10000] max-w-[100vw] animate-drawer shadow-xl fixed right-0 top-0 bottom-0 w-[450px] bg-white ",
          cartOpen ? "block" : "hidden",
        )}
        ref={cartRef}
      >
        <div
          ref={divRef}
          className="relative flex flex-col justify-between w-full overflow-y-scroll p-[20px] h-full max-h-[100vh]"
        >
          <div>
            <button aria-label="Close Cart" onClick={closeCart}>
              <IoIosClose className="w-[30px] h-[30px]" />
            </button>
            <h3 className="font-display py-[20px] text-center">Your Cart</h3>
            <hr />
            {!cart || !cart.lines.edges.length ? (
              <h4 className="font-display text-center py-[40px]">
                Your cart is empty!
              </h4>
            ) : (
              <div className=" divide-y-[1px]">
                {cart.lines.edges.map((item) => (
                  <div key={item.node.id}>
                    <CartItem item={item} />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="mt-auto">
            <hr />
            <div className="mt-[10px]">
              <input
                defaultChecked={agreedToTerms}
                onChange={(e) => {
                  setAgreedToTerms(e.target.checked)
                }}
                type="checkbox"
                name="terms"
                id="terms"
                data-cy="terms-agreement-input"
              />
              <label className="ml-2" htmlFor="terms">
                I agree to the Body Factory Bali Terms
              </label>
            </div>
            <div className="my-2 font-semibold">{amount}</div>
            <div className="flex items-center mt-[10px] justify-center w-full">
              <div>
                {membershipItems.length > 0 && user ? (
                  <OpenStartDateDialog disabled={!agreedToTerms} />
                ) : membershipItems.length > 0 && !user ? (
                  <OpenNonRecurringDialog disabled={!agreedToTerms} />
                ) : (
                  <button
                    aria-label="Proceed to checkout"
                    onClick={() => {
                      window.location.replace(cart?.checkoutUrl!)
                    }}
                    disabled={!agreedToTerms}
                    className="bg-brand disabled:opacity-30 disabled:pointer-events-none hover:bg-white focus:bg-white hover:text-brand focus:text-brand transition-colors text-black duration-150 ease-out rounded-[10px] font-bold text-md -skew-x-[21deg] inline-block pt-[20px]  pb-[16px] pl-[63px] pr-[53px] "
                  >
                    <div className="skew-x-[21deg]">Proceed to checkout</div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const OpenNonRecurringDialog: FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const [isVisible, divRef] = useInView()
  const { closeCart, setFetchData } = useCart()
  const { buttonLoading } = useMembershipBooking()

  useEffect(() => {
    if (isVisible) {
      setFetchData(true)
    }
  }, [isVisible])

  return (
    <Dialog>
      <DialogTrigger>
        <button
          aria-label="Proceed to checkout"
          // href={cart?.checkoutUrl || "#"}
          onClick={() => {
            closeCart()
          }}
          disabled={buttonLoading || disabled}
          className="bg-brand disabled:opacity-30 disabled:pointer-events-none hover:bg-white focus:bg-white hover:text-brand focus:text-brand transition-colors text-black duration-150 ease-out rounded-[10px] font-bold text-md -skew-x-[21deg] inline-block pt-[20px]  pb-[16px] pl-[63px] pr-[53px] "
        >
          <div ref={divRef} className="skew-x-[21deg]">
            Proceed to checkout
          </div>
        </button>
      </DialogTrigger>
      <NonRecurringDialog />
    </Dialog>
  )
}

const OpenStartDateDialog: FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const { buttonLoading, handleBooking } = useMembershipBooking()
  const [isVisible, divRef] = useInView()
  const { closeCart, setFetchData } = useCart()

  useEffect(() => {
    if (isVisible) {
      setFetchData(true)
    }
  }, [isVisible])

  return (
    <Dialog>
      <DialogTrigger>
        <button
          aria-label="Proceed to checkout"
          // href={cart?.checkoutUrl || "#"}
          data-cy="cart-start-date-dialog-trigger"
          onClick={() => {
            closeCart()
          }}
          disabled={buttonLoading || disabled}
          className="bg-brand  disabled:opacity-30 disabled:pointer-events-none hover:bg-white focus:bg-white hover:text-brand focus:text-brand transition-colors text-black duration-150 ease-out rounded-[10px] font-bold text-md -skew-x-[21deg] inline-block pt-[20px]  pb-[16px] pl-[63px] pr-[53px] "
        >
          <div ref={divRef} className="skew-x-[21deg]">
            Proceed to checkout
          </div>
        </button>
      </DialogTrigger>
      <SelectStartDateDialog bookMembership={handleBooking} />
    </Dialog>
  )
}

const CartItem: FC<{ item: ICart["lines"]["edges"][0] }> = ({
  item: {
    node: { id, merchandise, attributes, quantity },
  },
}) => {
  const [isVisible, divRef] = useInView()
  const { removeItem, setFetchData } = useCart()
  const amount = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(Number(merchandise.priceV2.amount))

  useEffect(() => {
    if (isVisible) {
      setFetchData(true)
    }
  }, [isVisible])

  return (
    <div ref={divRef} className="py-[20px] flex">
      <img
        src={merchandise.image.url}
        alt={merchandise.image.altText}
        className="w-[70px] md:w-[140px]"
      />
      <div className="flex-1 flex flex-col justify-between pt-[10px] pl-[10px]">
        <div className="flex justify-between">
          <h4 className="text-base font-semibold md:text-lg">
            {merchandise.product.title}
          </h4>
          <button
            aria-label="Remove item"
            onClick={() => {
              removeItem(id)
            }}
          >
            <AiFillDelete className="ml-[15px] w-[24px] h-[24px]" />
          </button>
        </div>
        {attributes.length > 0 && (
          <div className="text-sm">trainer chosen: {attributes[0].value}</div>
        )}
        <div className="flex justify-between text-base font-semibold">
          <div className="text-sm lg:text-base">
            quantity: {isNaN(quantity) ? 0 : quantity}
          </div>
          <div className="text-sm lg:text-base">{amount}</div>
        </div>
      </div>
    </div>
  )
}

export default Cart
